import React from 'react'
import './ui.css'

const PrimaryButton = ({ backgroundColor, color, title, borderWidth, paddingLeft, paddingRight, id, className  }) => {
    return (
        <div id={id} className={`primary-btn-div ${className}`} style={{ backgroundColor: backgroundColor ? backgroundColor : 'white', color: color ? color : 'black', borderColor: backgroundColor ? backgroundColor : 'white', borderWidth: borderWidth ? borderWidth : '2px', paddingLeft: paddingLeft, paddingRight: paddingRight  }}>
            <a href="https://www.dashboard.priya.ai/auth">
                {title}
            </a>
        </div>
    )
}

export default PrimaryButton
