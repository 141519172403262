import Footer from 'components/Footer'
import IndexNavbar from 'components/Navbars/IndexNavbar'
import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { AnchorScroll } from 'helper';
import { Helmet } from "react-helmet";
import { Link, useHistory, useLocation } from 'react-router-dom';
import './index.css'

const Research = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PRIYA | Research</title>
                <meta name="title" content="PRIYA | Research" />
                <meta name="description" content="We believe our research will lead to a more efficient economy while increasing small businesses' profits and consumer spending power." />
            </Helmet>
            <div className='bg-dark'>
                <section className="py-0 nav-section">
                    <IndexNavbar className="fixed-top" />
                </section>
                <section className='my-sm-5'>
                    <Row className='align-items-center'>
                        <Col md="6">
                            <h2 className="text-uppercase font-banner-research mb-0 ff-light text-banner mr-sm-4 mt-2">Creating a better economy with modern AI research </h2>
                        </Col>
                        <Col md="6">
                            <p className="text-gray font-style ff-light text-22 mt-4 mt-md-0">We believe our research will lead to a more efficient economy while increasing small businesses' profits and consumer spending power.</p>
                        </Col>
                    </Row>
                </section>
            </div>
            <div className='bg-white research-page'>
                <section className='py-5'>
                    <div className='ff-inter-semi text-dark text-center text-24 py-3'>Areas of research to optimize pricing and economic efficiency</div>
                    <Row className='mt-5'>
                        <Col sm="4" className='d-flex flex-column px-xl-4'>
                            <img src={require('../../../assets/img/Research/price-data.png').default} />
                            <h3 className='ff-inter-semi text-dark text-26 mb-2 mt-4'>Product pricing data over time</h3>
                            <p className='ff-inter-regular text-dark text-16'>We developed a deep learning model to help us interpret millions of price points across hundreds of industries</p>
                        </Col>
                        <Col sm="4" className='d-flex flex-column mt-5 mt-sm-0 px-xl-4'>
                            <img src={require('../../../assets/img/Research/consumer.png').default} />
                            <h3 className='ff-inter-semi text-dark text-26 mb-2 mt-4'>Consumer behavior data</h3>
                            <p className='ff-inter-regular text-dark text-16'>We trained a model to interpret shopping behavior, consumer sentiment, and buying attributes</p>
                        </Col>
                        <Col sm="4" className='d-flex flex-column mt-5 mt-sm-0 px-xl-4'>
                            <img src={require('../../../assets/img/Research/blog.png').default} />
                            <h3 className='ff-inter-semi text-dark text-26 mb-2 mt-4'>Macroeconomic factors</h3>
                            <p className='ff-inter-regular text-dark text-16'>We've trained a model that analyzes realtime macroeconomic events and conditions to update price functions</p>
                        </Col>
                    </Row>
                    <section className='px-0'>
                        <Row>
                            <Col sm="6" className='d-flex flex-column px-xl-4'>
                                <img src={require('../../../assets/img/Research/graph1.png').default} />
                                <h3 className='ff-inter-semi text-dark text-26 mb-2 mt-4' >Humans are not good at setting accurate prices.</h3>
                                <p className='ff-inter-regular text-dark text-16'>PRIYA is able to collect, analyze, and interpret thousands of times more pricing data at an astonishing rate compared to humans</p>
                            </Col>
                            <Col sm="6" className='d-flex flex-column mt-5 mt-sm-0 px-xl-4'>
                                <img src={require('../../../assets/img/Research/graph2.png').default} />
                                <h3 className='ff-inter-semi text-dark text-26 mb-2 mt-4' >Static prices cause economic inefficiencies</h3>
                                <p className='ff-inter-regular text-dark text-16'>Businesses start to lose efficiency when keeping the same price the same for long periods of time.</p>
                            </Col>
                        </Row>
                    </section>
                </section>
            </div>
            <div className='bg-white'>
                <section>
                    <div className='ff-inter-medium text-center text-uppercase text-dark'>Research Index</div>
                    <Link to='/research/what-is-an-ai-pricing-tool'><div className='d-flex justify-content-between border p-3 align-items-center mt-4 cursor-pointer'>
                        <div>
                            <button className='ff-book button-gradient text-16 px-3 py-2 '>What is an AI pricing tool?</button>
                            <div className='text-dark mt-2'>Learn what an AI pricing tool is, all of the biggest benefits it offers, and whether or not it may be a good fit for your own business</div>
                        </div>
                        <img src={require('../../../assets/img/Research/north_east.png').default} className="img-fluid" />
                    </div>
                    </Link>
                    <Link to='/research/11-best-Shopify-plugins-for-your-online-store'><div className='d-flex justify-content-between border p-3 align-items-center mt-4 cursor-pointer'>
                        <div>
                            <button className='ff-book button-gradient text-16 px-3 py-2 title-btn'>11 best Shopify plugins for your online store</button>
                            <div className='text-dark mt-2'>Here are 11 must-have plugins that we recommend adding to your Shopify website.</div>
                        </div>
                        <img src={require('../../../assets/img/Research/north_east.png').default} className="img-fluid" />
                    </div>
                    </Link>
                    <Link to='/research/what-separates-PRIYA-from-other-AI-pricing-tools'><div className='d-flex justify-content-between border p-3 align-items-center mt-4 cursor-pointer'>
                        <div>
                            <button className='ff-book button-gradient text-16 px-3 py-2 title-btn'>What separates PRIYA from other AI pricing tools?</button>
                            <div className='text-dark mt-2'>AI Pricing tools can help businesses to reach higher sales numbers, save valuable time, and compete in the increasingly competitive market. Here are a few benefits that an AI pricing tool can provide your business.</div>
                        </div>
                        <img src={require('../../../assets/img/Research/north_east.png').default} className="img-fluid" />
                    </div>
                    </Link>
                    <Link to='/research/why-dynamic-pricing-is-better'><div className='d-flex justify-content-between border p-3 align-items-center mt-4 cursor-pointer'>
                        <div>
                            <button className='ff-book button-gradient text-16 px-3 py-2 title-btn'>Why dynamic pricing is better</button>
                            <div className='text-dark mt-2'>In this post, we delve into why dynamic prices are better than static prices, including its various benefits, strategies for implementation, and examples of it across industries.</div>
                        </div>
                        <img src={require('../../../assets/img/Research/north_east.png').default} className="img-fluid" />
                    </div>
                    </Link>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default Research