import { useRef, useState } from "react";
import "./index.css";
import Carousel from "react-elastic-carousel";

import leftArrow from "../../assets/img/Home/carousel-left.svg"
import rightArrow from "../../assets/img/Home/carousel-right.svg"

const ButtonCarousel = ({ setActiveButtonIndex, activeButtonIndex }) => {
  const carouselRef = useRef(null);
  const buttons = [
    "Run Price Simulations",
    "Analyze Competition",
    "Product Performance",
    "Enable Autopilot Pricing™",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleButtonsCount, setVisibleButtonsCount] = useState(4);
  const totalItems = buttons.length;

  const visibleButtons = buttons.slice(
    currentIndex,
    currentIndex + visibleButtonsCount
  );

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext();
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  const handleEnd = (currentItem) => {
    setCurrentIndex(currentItem.index);
  };

  return (
    <div className="button-carousel-container">
      <div className="button-carousel-wrapper">
        <div className="button-grid" id="workBtn">
          {visibleButtons.map((buttonText, index) => {
            const isActive = index === activeButtonIndex;
            return (
              <button
                key={index}
                onClick={() => setActiveButtonIndex(index)}
                className={`button ${isActive ? "button-active" : "button-inactive"
                  }`}
              >
                {buttonText}
              </button>
            );
          })}
        </div>
        <div className="mobile-button">
          <Carousel
            ref={carouselRef}
            itemsToScroll={2}
            itemsToShow={2}
            pagination={false}
            showArrows={false} onNextEnd={handleEnd}
            onPrevEnd={handleEnd}
            onChange={(currentItem) => setCurrentIndex(currentItem.index)}
            className="mobile-button-carousel"
          >
            {buttons.map((buttonText, index) => {
              const isActive = index === activeButtonIndex;
              return (
                <button
                  key={index}
                  onClick={() => {
                    setActiveButtonIndex(index);
                  }}
                  className={`button ${isActive ? "button-active" : "button-inactive"
                    }`}
                >
                  {buttonText}
                </button>
              );
            })}
          </Carousel>
        </div>
        <div className="arrow-container">
          <div className="ArrowsDiv">
            <img
              src={currentIndex === 0 ? leftArrow : rightArrow}
              // onClick={() => carouselRef.current.slidePrev()}
              onClick={currentIndex !== 0 && handlePrev}
              alt="Previous arrow"
              style={{ opacity: currentIndex === 0 ? 0.5 : 1, rotate: currentIndex === 0 ? '0deg' : '180deg', cursor: currentIndex === 0 ? 'not-allowed' : 'pointer' }}
            />
            <img
              src={rightArrow}
              // onClick={() => carouselRef.current.slideNext()}
              onClick={currentIndex !== totalItems - 2 && handleNext}
              alt="Next arrow"
              style={{ opacity: currentIndex >= totalItems - 2 ? 0.2 : 1, cursor: currentIndex >= totalItems - 2 ? 'not-allowed' : 'pointer' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonCarousel;
