import Footer from "components/Footer";
import React from "react";
import Works from "./Works";
import Roadmap from "./Roadmap";
import Slide from "./Slide";
import Analysis from "./Analysis";
import Integration from "./Integration";
import Banner from "./Banner";
import { Helmet } from "react-helmet";
import PredictiveAnalysis from "./PredictiveAnalysis";
import PricingIntelligence from "./PricingIntelligence";
import AutoPilotPricing from "./AutoPilotPricing";
import TrustedMerchants from "components/TrustedMerchants";
import PriceEfficiencySection from "components/PriceEfficiencySection";
import './index.css'

export default function Index() {
  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    window.scrollTo({ top: 0, behavior: "smooth" });
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRIYA | The Pricing AI</title>
        <meta
          name="description"
          content="Trusted by brands all over the world, PRIYA AI analyzes millions of data points in real-time to automatically price products for maximum revenue and efficiency across hundreds of industries including electronics, apparel, beauty products, household goods, furniture, and more."
        />
      </Helmet>
      <div className="bg-dark" id="home">
        <Banner />
        <Works />
        <PredictiveAnalysis />
        <AutoPilotPricing />
        <TrustedMerchants />
        <PriceEfficiencySection />
        <Roadmap />
        {/* <Analysis />
        <Integration /> */}
        <PricingIntelligence />
        <Slide />
        <Footer />
      </div>
    </>
  );
}
