import React from 'react'
import "./TrustedMerchants.css"
import trusted from "../assets/img/Home/trusted.png"
import bgImg from "../assets/img/Home/linebg3.png"
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation } from 'swiper'
import { Row } from "reactstrap";
import Carousel from "react-elastic-carousel";

import t1 from "../assets/img/Home/t1.png"
import t2 from "../assets/img/Home/t2.png"
import t3 from "../assets/img/Home/t3.png"
import t4 from "../assets/img/Home/t4.png"
import t5 from "../assets/img/Home/t5.png"
import t6 from "../assets/img/Home/t6.png"

import leftArrow from "../assets/img/Home/leftArrow.svg"
import rightArrow from "../assets/img/Home/rightArrow.svg"


const TrustedMerchants = () => {

  const slides = [
    {
      image: (
        <img src={t1} alt="t1" className='t1' />
      )
    },
    {
      image: (
        <img src={t2} alt="t2" className='t2' />
      )
    },
    {
      image: (
        <img src={t3} alt="t3" className='t3' />
      )
    },
    {
      image: (
        <img src={t4} alt="t4" className='t4' />
      )
    },
    {
      image: (
        <img src={t5} alt="t5" className='t5' />
      )
    },
    {
      image: (
        <img src={t6} alt="t6" className='t6' />
      )
    },
  ]

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === "PREV" ? leftArrow : rightArrow;
    return (
      <div className={`trustArrowsDiv ${isEdge ? "disabled" : ""}`}>
        <img width={34} height={34} onClick={onClick} disabled={isEdge} src={pointer} alt={`${type} arrow`} />
      </div>
    );
  };

  return (
    <div className='bg-black text-white merchantsMain'>
      <div className='merchantsBg'>
        <img src={bgImg} alt="Background Line grid" />
      </div>
      <div className='merchantsDiv1' >
        <img src={trusted} alt="Trusted" className='trustedImg' />
        <div className='merchantsDiv1Content'>
          <div>Trusted by over 300+ leading Shopify merchants</div>
          <div>Ecommerce merchants use PRIYA for trusted analytics, projections, and accurate pricing to maximize and conversion rate</div>
        </div>
      </div>
      <div className='merchantsDiv2' >
        <div className='merchantsDiv2Content'>
          <img src={t1} alt="t1" className='t1' />
          <img src={t2} alt="t2" className='t2' />
          <img src={t3} alt="t3" className='t3' />
        </div>
        <div className='merchantsDiv2Content'>
          <img src={t4} alt="t4" className='t4' />
          <img src={t5} alt="t5" className='t5' />
          <img src={t6} alt="t6" className='t6' />
        </div>
      </div>
      <Row id='customSliderMain' data-aos="zoom-in" className="graph-items d-xl-none custom-slider ">
        <Carousel disableArrowsOnEnd={true} renderArrow={myArrow} itemsToShow={1} >
          {slides.map((slide, index) => {
            return (
              <div key={index} className='custom-slider-div'>
                {slide.image}
              </div>
            );
          })}
        </Carousel>
      </Row>
    </div>
  )
}

export default TrustedMerchants
