/*!

=========================================================
* priya_ai - v1.0.0
=========================================================
* Copyright © Y CORP
*/
import React from "react";
import ReactDOM from "react-dom";
import Routes from "routes";
// ** Redux Imports
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../src/redux/rootReducer";
import { ToastContainer } from "react-toastify";

import "assets/css/nucleo-icons.css";
import "assets/scss/priya_ai.scss?v=1.2.0";
import "assets/demo/demo.css";
import "assets/css/billing_section.css";
import "flatpickr/dist/themes/material_green.css";

// ** React Toastify
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

const store = configureStore({
  reducer: rootReducer,
});

ReactDOM.render(
  <Provider store={store}>
    <Routes />
    <ToastContainer newestOnTop limit={1} />
  </Provider>,
  document.getElementById("root")
);
