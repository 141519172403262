"use client";
import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";

export default function YoutubePlayer() {
  const [videoSize, setVideoSize] = useState({ width: 200, height: 150 });

  const getVideoSize = (width) => {
    if (width < 440) {
      return { width: '100%', height: 190 };
    }
    else if (width < 640) {
      return { width: 390, height: 230 };
    } else if (width < 768) {
      return { width: 490, height: 290 };
    } else if (width < 1024) {
      return { width: 690, height: 300 };
    } else if (width < 1280) {
      return { width: 850, height: 390 };
    } else if (width < 1536) {
      return { width: 1150, height: 530 };
    } else {
      return { width: 1150, height: 530 };
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setVideoSize(getVideoSize(window.innerWidth));
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const opts = {
    height: videoSize.height,
    width: videoSize.width,
  };

  return (
    <div style={{ borderRadius: '14px'}} className="relative h-20 w-full">
      <div className="h-20 w-full bg-white"></div>
      <div className="flex h-20 items-center justify-center">
        <div style={{ borderRadius: '14px'}} className="">
          <YouTube videoId="lEqHLVTf4p4" className="" style={{ borderRadius: '14px'}} opts={opts} />
        </div>
      </div>
    </div>
  );
}
