import { Col, Row } from "reactstrap";
import { FaStar } from "react-icons/fa";

const Testimonial = ({
  name,
  type,
  imageFileName,
  starCount,
  reviewContent,
}) => {
  return (
    <Col
      className="d-flex"
      style={{
        borderRadius: "10px",
        "box-shadow": "0 4px 8px rgba(0, 0, 0, 0.1)",
        flexDirection: "column",
        gap: "1rem",
        padding: "1.5em",
      }}
    >
      <Row>
        <div
          style={{
            border: "1px solid #D2D2D2",
            borderRadius: "40px",
            padding: "0.5rem",
          }}
        >
          <img
            src={require(`../../assets/img/Priya_AI/${imageFileName}`).default}
            height={"40px"}
            width={"40px"}
          />
        </div>
        <Col
          className="d-flex"
          style={{ flexDirection: "column", alignItems: "flex-start" }}
        >
          <a
            href={`https://www.${name}`}
            target="_blank"
            style={{ fontSize: "20px", color: "#08B8D8" }}
          >
            {name}
          </a>
          <p className="text-gray ff-light">{type}</p>
        </Col>
      </Row>
      <p
        className="text-dark ff-light"
        style={{ textAlign: "left", fontSize: "20px" }}
      >
        {reviewContent}
      </p>
      <div className="d-flex">
        {Array.from({ length: starCount }).map((_, index) => (
          <FaStar key={index} color="#FDCC0D" size={20} />
        ))}
      </div>
    </Col>
  );
};

export default Testimonial;
