import React from "react";
import { Col, Row } from "reactstrap";

import DashboardImage from "../../../assets/img/dashboard6.png";
import search from "../../../assets/img/search.png";
import "./predictive.css";
import PrimaryButton from "components/ui/PrimaryButton";

export default function PredictiveAnalysis() {
  return (
    <div className="predicitive-container" id="predictiveAnalysis" >
      <Row className="d-flex flex-column-reverse flex-lg-row">
        <Col style={{ marginLeft: 10 }} className="px-md-5">
          {/* <div className="text-content"> */}
          <div className="header">
            <img src={search} alt="search Icon" />
            <div className="predictive-title">Predictive Intelligence</div>
          </div>
          <p className="predictive-description">
            See how your revenue will change if you change your prices
          </p>
          <div className="gap-2 d-flex justify-content-md-start justify-content-lg-start mt-4">
            <PrimaryButton backgroundColor='black' color='white' title='RUN PRODUCT ANALYSIS' />
          </div>
          {/* </div> */}
        </Col>
        <Col>
          <div className="predictiveBtnImg">
            <img src={DashboardImage} alt="Description of the image" />
          </div>
        </Col>
      </Row>
    </div>
  );
}
