import Footer from 'components/Footer'
import IndexNavbar from 'components/Navbars/IndexNavbar'
import React, { useEffect } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { AnchorScroll } from 'helper';
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from 'react-router-dom';

const ArticleThree = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PRIYA | Research | 11 best Shopify plugins for your online store</title>
                <meta name="title" content="PRIYA | Research | 11 best Shopify plugins for your online store" />
                <meta name="description" content="Here are 11 must-have plugins that we recommend adding to your Shopify website." />
            </Helmet>
            <div className='bg-dark'>
                <section className="py-0 nav-section">
                    <IndexNavbar className="fixed-top" />
                </section>
                <section className='my-sm-5'>
                    <Row className='align-items-center'>
                        <Col lg="10" xl="7">
                            <p className='ff-inter-regular'>Feb 7, 2024</p>
                            <h2 className="text-uppercase font-banner-research mb-0 ff-light text-banner mr-sm-4 mt-4">What Separates PRIYA from other AI pricing tools?</h2>
                            <p className="text-gray font-style ff-light text-22 mt-4">AI Pricing tools can help businesses to reach higher sales numbers, save valuable time, and compete in the increasingly competitive market. Here are a few benefits that an AI pricing tool can provide your business.</p>
                        </Col>
                        <Col lg="2" xl="5">
                        </Col>
                    </Row>
                </section>
            </div>
            <div className='bg-white'>
                <section>
                    <h1 className='ff-book text-dark text-center text-35'>What Separates PRIYA from other AI pricing tools?</h1>
                    <p className='ff-inter-regular text-dark text-16 mt-4'>Studies have shown that businesses may lose up to 35%+ in potential sales due to bad pricing. Because of this, having the optimal pricing for one’s products is absolutely vital. And in today’s tech-driven world, investing in an AI pricing tool is the best way to quickly and effectively price your store’s products.</p>
                    <p className='ff-inter-regular text-dark text-16 mt-4'>PRIYA AI is the next step in advanced online pricing tools, utilizing a deep learning model that finds the best prices for products while being much faster and more accurate than manual pricing.</p>
                    <div className='pt-5'>
                        <h2 className='ff-book text-dark mb-1 text-30'>Benefits of Using an AI Pricing Tool</h2>
                        <p className='ff-inter-regular text-dark text-16 mt-2'>AI Pricing tools can help businesses to reach higher sales numbers, save valuable time, and compete in the increasingly competitive market. Here are a few benefits that an AI pricing tool can provide your business:</p>
                        <ul>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Save Time:</span> Pricing your store’s products can take a lot of valuable time, especially if you
                                carry a large amount of items. Moreover, products typically change in price over time,
                                meaning that those who price their products manually will also have to change those
                                prices one at a time if they want to lower or raise prices. Through the use of an AI pricing
                                tool, you can automate the entire process of pricing and repricing your products.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>More Accurate:</span> Not only are AI pricing tools great at saving you time, but chances are
                                they are also much more accurate in finding the best price for your products – which
                                directly results in more sales and higher revenue for your business. Studies have shown
                                that PRIYA AI can be up to 73% more accurate at pricing store products than humans.
                                This adds up fast and can make the difference between success and failure for your
                                business.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Maximize ROI:</span> AI pricing tools are capable of analyzing your store’s historical sales
                                data, along with competitor’s pricing and other information to implement dynamic pricing
                                strategies and personalization pricing strategies that will help to increase your sales
                                numbers and maximize ROI.</li>
                        </ul>
                    </div>
                    <div className='pt-5'>
                        <h2 className='ff-book text-dark mb-1 text-30'>What separates PRIYA from other AI pricing tools?</h2>
                        <p className='ff-inter-regular text-dark text-16 mt-2'>So we’ve explained why AI pricing tools are important, and how they can help your business.
                            But why use PRIYA over other AI pricing tools? What sets itself apart from the competition?
                            Here are the biggest factors that set PRIYA apart from other AI pricing tools:</p>
                        <ul>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Uses an Advanced Deep Learning Model:</span> PRIYA was developed using a proprietary
                                deep learning model. It was used to train PRIYA on millions of pricing data points across
                                various products, services, and industries to establish initial data that is fed to the model. This info is used to help determine an optimal "price prediction over time" on a
                                per-product basis.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Constantly Updated:</span> The output is a mathematical formula that is updated up to every
                                hour on every product PRIYA is connected to. This formula determines how prices
                                should move over time along with other macro and micro economic conditions such as
                                market conditions, sales activity, user behavior, etc.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Improves Over Time:</span> PRIYA was trained via both supervised and unsupervised
                                learning methods and can learn by itself how to better price products the sales and
                                product data comes in. This consists of countless amounts of price-testing, training, and
                                learning.</li>
                        </ul>
                    </div>
                    <div className='pt-5'>
                        <h2 className='ff-book text-dark mb-1 text-30'>Price Prediction Model (PPM)</h2>
                        <p className='ff-inter-regular text-dark text-16 mt-2'>PRIYA is powered by an advanced Price Prediction Model (PPM). Here’s what it is and how it
                            works:</p>
                        <ul>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Utilizing an Advanced Algorithm:</span> PPM is a highly trained transformer model that is
                                used to run, train, and predict product prices over time. Ultimately, its goal is to predict
                                the price that has the highest likelihood of a conversion.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Interprets Macro and Micro Economic Behavior:</span> PPM has been trained to predict
                                macro and micro economic conditions including economic conditions, competitor data,
                                supply chain costs, consumer behavior, and sales data to best predict prices in real-time.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Advanced Learning on a Per-Product Basis:</span> PPM enables accurate price
                                predictions on a granular per-product basis. This means that products are optimized on
                                an individualized, with every single product or service optimized separately. PPM is
                                also trained on an industry level for products ranging from electronics to apparel, and
                                everything else in between.</li>
                            <li className='ff-inter-regular text-dark text-16'><span className='font-weight-bold text-16'>Helping PRIYA Learn Through Use:</span> PPM learns through experience, meaning that
                                by connecting PRIYA to your store, you are helping her learn and improve pricing
                                accuracy over time. By using PRIYA, you’re effectively participating in training her so that
                                both you and all of the other businesses utilizing her benefit.</li>
                        </ul>
                    </div>
                    <div className='pt-5'>
                        <h2 className='ff-book text-dark mb-1 text-30'>How PRIYA works</h2>
                    </div>
                    <div className='pt-4'>
                        <img src={require('../../../assets/img/Research/Article3/how-priya-works.png').default} className="img-fluid width-75" />
                        <p className='ff-inter-regular text-dark text-16 mt-2'>PRIYA AI connects to your store to automatically update prices through the use of real-time data
                            analytics and advanced pricing algorithms. It’s fairly easy to use, though there are a few things
                            to know before starting. Here’s a quick guide on how it works:</p>
                        <ol className='mt-5'>
                            <li className='ff-inter-regular text-dark text-16'>On the left-hand side of the screen, you’ll see the tabs for navigating the site:
                                Dashboard, Billing, Settings, and Logout. Dashboard is where most of your time will be
                                spent, so let’s focus on that section. At the top of the Dashboard, you’ll find your store
                                details and PRIYA’s learning status with your store. The learning status indicates how
                                well PRIYA has learned to price your products based on 3 phases. By the time the store
                                reaches the “optimizing” phase, all of your connected products will have reached a high
                                level of price optimization.
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_1.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Below that, you’ll find your products which have all been automatically uploaded from
                                Shopify. When you first create an account, products that have just been added will say
                                <span className='font-weight-bold text-16'>“Collecting Data”</span> which indicates that PRIYA is still finding and collecting pricing data
                                for the respective products. This is only temporary, and the estimated time needed should be displayed.
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_2.png').default} className="img-fluid d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Once this is finished, products should look like this. You’ll find the current price PRIYA
                                has set for each of your products. This price has been determined by PRIYA’s algorithm,
                                though users have the option to turn off PRIYA for specific products or view additional
                                data. If you wish to turn off PRIYA, simply click on the green toggle. If you want to see
                                more data, select <span className='font-weight-bold text-16'>“View Data.”</span> You may also use the search bar to search for a
                                particular product.
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_3.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>When you select <span className='font-weight-bold text-16'>“View Data,”</span> this window will pop up. Here, you’ll be able to find the
                                “price efficiency” score which tells you how optimal the pricing is for that product – in
                                other words, how effective PRIYA’s pricing has been for that particular product. To see
                                how the product’s price has changed over time (and to see how the sales has changed
                                from price to price), select <span className='font-weight-bold text-16'>“View Price History.”</span> If you want to manually set the price of
                                a product, then simply select <span className='font-weight-bold text-16'>“Set Floor Price.”</span>
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_4.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>You can view the product’s price history via the <span className='font-weight-bold text-16'>“View Price History”</span> option which
                                allows users to see how the price of a product has fluctuated over time. You can filter the
                                price by specific dates, and see the Sales Change percentage to easily see how much
                                the sales rate changed over time. This can give the user a deeper insight into how
                                pricing changes effects the sales of their products.
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_5.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>You also have the option to manually set the floor price for an item. To do so, simply type
                                the desired price into the field and then select “Save.”
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_6.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                        </ol>
                    </div>
                    <div className='pt-5'>
                        <h2 className='ff-book text-dark mb-1 text-30'>How to Install PRIYA</h2>
                    </div>
                    <div className='pt-4'>
                        <p className='ff-inter-regular text-dark text-16 mt-2'>Because of its advanced nature, PRIYA cannot simply be installed by simply visiting the Shopify
                            App store. Instead, the process of adding it to your store is a bit more hands-on. Here is the
                            process that users will have to undergo:</p>
                        <ol className='mt-5'>
                            <li className='ff-inter-regular text-dark text-16'>Visit <a href='https://priya.ai/' className='text-blue'>Priya.ai</a> and select <span className='font-weight-bold text-16'>“Try PRIYA.”</span> You will then be given the choice between installing
                                using Shopify or simply copying/pasting code manually. Currently installing via Shopify is
                                the only option available.
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_7.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Start by going to the Shopify Admin panel and click on the <span className='font-weight-bold text-16'>“Settings”</span> option.
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_8.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Next, select  <span className='font-weight-bold text-16'>“Notifications.”</span>
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_9.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>At the bottom of the Notification Page select <span className='font-weight-bold text-16'>"Webhooks"</span> and then select the option
                                titled <span className='font-weight-bold text-16'>"Create Webhook."</span> option.
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_10.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>From the options available, select the one marked<span className='font-weight-bold text-16'>“Order Payment.”</span>
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_11.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Make sure the format is JSON.
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_12.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Copy the URL and then paste it into your webhook URL section. URL:
                                <span className='text-blue'>https://api.priya.ai/api/v1/get-sales-data/</span>
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_13.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Webhook API version should be marked as <span className='font-weight-bold text-16'>"unstable"</span> by default. Select <span className='font-weight-bold text-16'>“Save.”</span>
                                <img src={require('../../../assets/img/Research/Article3/Screenshot_14.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Next, go back to your Shopify Home Screen. Select <span className='font-weight-bold text-16'>“Apps”</span> on the left-hand side of the
                                screen.
                                <img src={require('../../../assets/img/Research/Article3/Step2.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Select <span className='font-weight-bold text-16'>"App and sales channels."</span>
                                <img src={require('../../../assets/img/Research/Article3/Step3.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Select the <span className='font-weight-bold text-16'>"Develop Apps"</span> option, allow permission, and then click on the <span className='font-weight-bold text-16'>“Create
                                App”</span> option.
                                <img src={require('../../../assets/img/Research/Article3/Step4.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Enter <span className='font-weight-bold text-16'>“PRIYA AI”</span> as the App name, and then click <span className='font-weight-bold text-16'>“Create app.”</span> From the <span className='font-weight-bold text-16'>“App
                                Development”</span> screen, click on the <span className='font-weight-bold text-16'>“Configure Admin API Scopes”</span> option.
                                <img src={require('../../../assets/img/Research/Article3/Step5.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Under the <span className='font-weight-bold text-16'>"Configuration"</span> tab, select all of the checkboxes.
                                <img src={require('../../../assets/img/Research/Article3/Step6.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>At the bottom of this screen, select <span className='font-weight-bold text-16'>"2023-04"</span> from the <span className='font-weight-bold text-16'>"Event Version"</span> dropdown and
                                then select the <span className='font-weight-bold text-16'>"Save”</span> option.
                                <img src={require('../../../assets/img/Research/Article3/Step7.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>Go to the <span className='font-weight-bold text-16'>"API Credential"</span> tab and select <span className='font-weight-bold text-16'>"Install App"</span> to install the PRIYA AI app.
                                <img src={require('../../../assets/img/Research/Article3/Step8.png').default} className="img-fluid width-75 d-block my-4" />
                            </li>
                            <li className='ff-inter-regular text-dark text-16'>In the Configuration step of your setup process, you’ll be pasting your Shopify store’s
                                Domain, Admin API Access Token, and your API key. Once it’s all entered properly,
                                select <span className='font-weight-bold text-16'>“Verify Connection.”</span> And boom – you’re all set!
                                <img src={require('../../../assets/img/Research/Article3/Step9.png').default} className="img-fluid width-75 d-block my-4" /> This has been just a brief overview of how PRIYA works, the setup process, and all of its
                                important features. This should be enough information to get yourself started with using
                                PRIYA AI!
                            </li>
                        </ol>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default ArticleThree