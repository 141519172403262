import React from "react";
import "./customStyles.css";
import YoutubePlayer from "./VideoPlayer";
import PrimaryButton from "components/ui/PrimaryButton";
import SecondaryButton from "components/ui/SecondaryButton";

export default function PricingIntelligence() {
  return (
    <div className="pricing-container pb-0">
      <div className="text-content">
        <p className="unlock-revenue">UNLOCK MORE REVENUE</p>
        <p className="main-text">
          Next generation pricing intelligence using the power of AI
        </p>
        <p className="-description-text">
          Everything you need to understand your pricing, competitions, revenue
          and more
        </p>
      </div>
      <div style={{ marginBottom: '70px' }} className="gap-2 d-flex justify-content-md-center justify-content-lg-start mt-5">
        <PrimaryButton id="desktop-hero-primary-btn" backgroundColor='black' color='white' title='RUN PRODUCT ANALYSIS' borderWidth='1.5px' />
        <PrimaryButton id="mobile-hero-primary-btn" className="secondary-btn-div mobile-primary-ml" backgroundColor='black' color='white' title='RUN ANALYSIS' borderWidth='1.5px' />
        <SecondaryButton title='BOOK A DEMO' color='black' borderColor='black' borderWidth='1.5px' className="mobile-secondary-ml" />
      </div>
      <YoutubePlayer />
    </div>
  );
}
