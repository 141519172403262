import Footer from 'components/Footer'
import IndexNavbar from 'components/Navbars/IndexNavbar'
import React, { useState, useEffect } from 'react'
import { Col, Input, Row } from 'reactstrap'
import { Helmet } from "react-helmet";
import { Service } from "services/Service";
import Loader from 'components/Loader';
import BillingPlans from "components/BillingPlans"
import './index.css'
const Pricing = () => {
    const [count, setCount] = useState(0);
    const [price, setPrice] = useState(0);
    const [freeAPICall, setFreeAPICall] = useState('')
    const [perAPICall, setPerAPICall] = useState('')
    const [priceUpdate, setPriceUpdate] = useState(0)
    const [perAPICost, setPerAPICost] = useState(0)
    const [loading, setLoading] = useState(false)

    const estimateYourCost = (count) => {
        if (count < 0) {
            setCount(0);
            setPrice(0);
        } else {
            setPrice(count * priceUpdate * 30);
        }
    }
    const getFreeAPICalls = () => {
        setLoading(true)
        Service.get({
            url: `/free-api-calls/`,
            formdata: true
        })
            .then(response => {
                if (response && response.status === true) {
                    setFreeAPICall(response.free_api_calls && response.free_api_calls.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));
                    setPerAPICall(response.per_api_call);
                    setPriceUpdate(response.price_update)
                    setPerAPICost(response.per_api_cost)
                }
                setLoading(false)
            }
            )
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        getFreeAPICalls()
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>PRIYA | Pricing</title>
                <meta name="title" content="PRIYA | Pricing" />
                <meta name="description" content="Simple pay-as-you-go pricing. PRIYA automatically works for you to optimize pricing as much as possible for your products." />
            </Helmet>
            <div className='bg-dark'>
                <section className="py-0 nav-section">
                    <IndexNavbar className="fixed-top" />
                </section>
                <section>
                    <div className="">
                        <h2 className="text-uppercase font-banner mb-0 ff-light text-banner mr-sm-4 mt-2 mb-0">Pricing </h2>
                        <p className="text-gray font-style ff-light mt-2">Choose from 3 plans to start using PRIYA</p>
                    </div>
                </section>
                <div className='bg-white position-rel'>
                    <section>
                        {/* <Row className='d-flex top-card'> */}
                        <BillingPlans/>
                            {/* <Col lg="4" className='bg-darkgray p-5'>
                                {loading ? <div className='d-flex align-items-center justify-content-center h-100'><Loader /></div> : <div className='d-flex flex-column justify-content-between align-items-center'>
                                    <div>
                                        <h3 className='ff-inter-regular text-darkgray mb-1 w-fitcontent text-center'>Up to {freeAPICall} API calls: </h3>
                                        <h2 className='ff-inter-semi text-dark text-center'>FREE</h2>
                                    </div>
                                    <div className='ff-inter-regular text-gray'>and then</div>
                                    <div>
                                        <h3 className='ff-inter-regular text-darkgray mb-1 mt-4 text-center'>Per API call: </h3>
                                        <h2 className='ff-inter-semi text-dark mb-0 text-center'>${perAPICall}</h2>
                                    </div>
                                </div>}
                            </Col>
                            <Col lg="8" className='bg-gray2 p-3 p-md-5'>
                                <h4 className='text-darkgray price-header ff-medium text-uppercase'>Pricing Calculator</h4>
                                <div className='bg-white rounded p-4'>
                                    <p className='ff-inter-regular text-darkgray'>Estimate your cost to use PRIYA</p>
                                    <div className='d-flex flex-column flex-sm-row my-4'>
                                        <div className='position-rel'>
                                            <div className='d-flex justify-content-start justify-content-sm-center align-items-start align-items-sm-center'>
                                                <Input
                                                    type='number'
                                                    min={0}
                                                    className='bg-white'
                                                    placeholder='Total number of products'
                                                    value={count !== 0 ? count : ''}
                                                    onChange={e => { setCount(e.target.value) }}
                                                />
                                            </div>
                                            <div className='text-left text-sm-right mt-4 mt-sm-0'>
                                                <button className='ff-inter-semi button-gradient text-16 px-3 py-2' onClick={() => estimateYourCost(count)}>Estimate Cost</button>
                                            </div>
                                        </div>
                                        <div className='ml-0 ml-sm-3 mt-4 mt-sm-0 text-left text-sm-right '>
                                            <span className='ff-inter-medium price-text-box px-3 py-2 py-sm-3 d-flex align-items-center justify-content-start'><div className='d-flex align-items-center'><div className='ff-inter-medium text-dark text-24'>${(price * perAPICost).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</div><span className='text-darkgray ff-inter-regular ml-1 text-14'>/ month</span></div></span>
                                        </div>
                                    </div>
                                    <div>
                                        <p className='ff-inter-regular text-gray'>This is only a rough estimate, actual costs may vary.</p>
                                    </div>
                                </div>
                            </Col> */}
                        {/* </Row> */}
                        <h2 className='d-block d-md-none text-dark ff-inter-regular mt-5 text-center mb-3'>On average, 46% of potential revenue is lost by small businesses due to sub-optimal pricing.</h2>
                        <h2 className='d-none d-md-block text-dark ff-inter-regular text-center mt-5 mb-1'>On average, 46% of potential revenue is lost by small</h2>
                        <h2 className='d-none d-md-block text-dark ff-inter-regular text-center mb-3'>businesses due to sub-optimal pricing.</h2>
                        <div className='text-dark text-16 ff-inter-regular text-center'>PRIYA automatically works for you to optimize pricing as much as possible.</div>
                        <div className='d-flex flex-column flex-lg-row justify-content-center align-items-center mt-5'>
                            <div className='bg-pricing-box p-3 p-sm-4  mr-lg-3 d-flex flex-column justify-content-between'>
                                <div>
                                    <h4 className='ff-inter-semi text-dark'>Apparel Industry</h4>
                                    <p className='ff-inter-regular text-darkgray'>Estimated Revenue Loss Due to Pricing Inefficiencies</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center mt-2'>
                                    <div className='d-flex align-items-center'><div className='ff-inter-medium text-dark text-24'>- $553M</div><span className='text-darkgray ff-inter-regular ml-1'>/ year</span></div>
                                    <img src={require('../../../assets/img/Pricing/jacket.png').default} width={100} />
                                </div>
                            </div>
                            <div className='bg-pricing-box p-3 p-sm-4 mr-lg-3 d-flex flex-column justify-content-between mt-4 mt-lg-0'>
                                <div>
                                    <h4 className='ff-inter-semi text-dark'>Electronics</h4>
                                    <p className='ff-inter-regular text-darkgray'>Estimated Revenue Loss Due to Pricing Inefficiencies</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center mt-2'>
                                    <div className='d-flex align-items-center'><div className='ff-inter-medium text-dark text-24'>- $611M</div><span className='text-darkgray ff-inter-regular ml-1'>/ year</span></div>
                                    <img src={require('../../../assets/img/Pricing/electronics.png').default} width={150} />
                                </div>
                            </div>
                            <div className='bg-pricing-box p-3 p-sm-4  d-flex flex-column justify-content-between mt-4 mt-lg-0'>
                                <div>
                                    <h4 className='ff-inter-semi text-dark'>Beauty &amp; Care</h4>
                                    <p className='ff-inter-regular text-darkgray'>Estimated Revenue Loss Due to Pricing Inefficiencies</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center mt-2'>
                                    <div className='d-flex align-items-center'><div className='ff-inter-medium text-dark text-24'>- $357M</div><span className='text-darkgray ff-inter-regular ml-1'>/ year</span></div>
                                    <img src={require('../../../assets/img/Pricing/makeup.png').default} width={100} />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column flex-lg-row justify-content-center align-items-center mt-4'>
                            <div className='bg-pricing-box d-flex flex-column justify-content-between p-3 p-sm-4  mr-lg-3'>
                                <div>
                                    <h4 className='ff-inter-semi text-dark'>Food &amp; Beverage</h4>
                                    <p className='ff-inter-regular text-darkgray'>Estimated Revenue Loss Due to Pricing Inefficiencies</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center mt-2'>
                                    <div className='d-flex align-items-center'><div className='ff-inter-medium text-dark text-24'>- $885M</div><span className='text-darkgray ff-inter-regular ml-1'>/ year</span></div>
                                    <img src={require('../../../assets/img/Pricing/Foods.png').default} width={130} />
                                </div>
                            </div>
                            <div className='bg-pricing-box p-3 p-sm-4  d-flex flex-column justify-content-between mt-4 mt-lg-0'>
                                <div>
                                    <h4 className='ff-inter-semi text-dark'>Pet Products</h4>
                                    <p className='ff-inter-regular text-darkgray'>Estimated Revenue Loss Due to Pricing Inefficiencies</p></div>
                                <div className='d-flex justify-content-between align-items-center mt-2'>
                                    <div className='d-flex align-items-center'><div className='ff-inter-medium text-dark text-24'>- $215M</div><span className='text-darkgray ff-inter-regular ml-1'>/ year</span></div>
                                    <img src={require('../../../assets/img/Pricing/dog-collars.png').default} width={120} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer /></div>
        </>
    )
}

export default Pricing