const initialState = {
    isLoginModalOpen: false,
    isSignupModalOpen: false,
    productList: [],
    isFetchProduct: false
};

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'OPEN_LOGIN_MODAL':
            return {
                ...state,
                isLoginModalOpen: action.payload
            };
        case 'OPEN_SIGNUP_MODAL':
            return {
                ...state,
                isSignupModalOpen: action.payload
            };
        case 'GET_PRODUCT_LIST':
            return {
                ...state,
                productList: action.payload,
                isFetchProduct: action.isFetching
            };
        default:
            return state;
    }
};

export default modalReducer;