/*!

=========================================================
* priya_ai - v1.0.0
=========================================================
* Copyright © Y CORP
*/
import React, { useState } from "react";
// reactstrap components
import { Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Config from "../../configs/config.json";
import './index.css'
import bgImg from "../../assets/img/Home/footerbg.png"
import PrimaryButton from "components/ui/PrimaryButton";
import SecondaryButton from "components/ui/SecondaryButton";

export default function Footer({ text }) {
  const Integration_Process = secureLocalStorage.getItem("integration_process");
  const user_logged = secureLocalStorage.getItem("is_loggedin");

  return (
    <>
      <div className="bg-dark" id="footer-section">
        <div className="bg-footer">
          <div className="footer-section-div">
            <section>
              <p className="ff-light text-banner font-gradient-text text-center" id="experience-priya">
                EXPERIENCE PRIYA
              </p>
              {/* <h2 className='text-white ff-light work-head mt-3 text-center mb-0'>Get started for free now.</h2> */}
              <h2 className="text-white ff-light work-head mt-3 text-center mb-0">
                {text ? text : "Get started for free now."}
              </h2>
              <div className="text-center">
                <div className="gap-2 d-flex justify-content-center mt-4">
                  <PrimaryButton title='TRY PRIYA' backgroundColor='#eaeaea' paddingLeft='35px' paddingRight='35px' borderWidth='1px'  className="secondary-btn-div mobile-primary-ml" />
                  <SecondaryButton title='BOOK A DEMO' borderWidth='1px' borderColor='white' className="mobile-secondary-ml" />
                  {/* <button
                    className="btn-white btn-banner ff-bold text-dark mt-3 w-full py-3"
                    onClick={() =>
                      (window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/auth`)
                    }
                  >
                    <a className="anchorTag" href="https://www.dashboard.priya.ai/auth">TRY PRIYA</a>
                  </button>
                  <a
                    target="_blank"
                    href="https://calendly.com/priyaai/30min"
                    className="py-3  btn-banner mt-3 btn-transparent text-16 d-flex justify-content-center align-items-center text-white ff-bold"
                  >
                    BOOK A DEMO
                  </a> */}
                </div>
              </div>
            </section>
          </div>
        </div>
        <div data-aos="zoom-in" className="py-4">

          <div className="border-bottom-style">
            <Row className="justify-content-center align-items-center footer-Link-Main">
              <Col
                xs="12"
                className="mt-3 mt-lg-0 d-flex flex-row justify-content-center"
              >
                <a href="#">
                  <img
                    src={require("../../assets/img/Priya_AI/logo.png").default}
                    alt="logo"
                    className="img-fluid logo-beta"
                    width={155}
                  />
                </a>
              </Col>
              <Col xs="12" className="">
                <div id="footerLinks" className="nav-link-space d-flex flex-column flex-lg-row align-items-center justify-content-center text-primary">
                  <Link to="/ppm-1" className="footer-text ff-book lg-px-5">
                    PPM-1
                  </Link>
                  <img
                    className="footer-link-separator"
                    src={
                      require("../../assets/img/Priya_AI/footer-border.png")
                        .default
                    }
                  />
                  <Link href="/pricing" className="footer-text ff-book lg-px-5">
                    Pricing
                  </Link>
                  <img
                    className="footer-link-separator"
                    src={
                      require("../../assets/img/Priya_AI/footer-border.png")
                        .default
                    }
                  />
                  <Link to="/research" className="footer-text ff-book lg-px-5">
                    Research
                  </Link>
                  <img
                    className="footer-link-separator"
                    src={
                      require("../../assets/img/Priya_AI/footer-border.png")
                        .default
                    }
                  />
                  <Link to="/about" className="footer-text ff-book lg-px-5">
                    About
                  </Link>
                  <img
                    className="footer-link-separator"
                    src={
                      require("../../assets/img/Priya_AI/footer-border.png")
                        .default
                    }
                  />
                  {/* <div className="footer-text ff-book px-5 cursor-pointer" onClick={() => window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/login`}>Login</div> */}
                  {Integration_Process === "completed" ? (
                    <a
                      href={`${process.env.REACT_APP_DASHBOARD_URL}/`}
                      target={"_blank"}
                      className="footer-text ff-book lg-px-5"
                    >
                      Dashboard
                    </a>
                  ) : Integration_Process === "not_completed" &&
                    user_logged === "done" ? (
                    <a
                      href={`${process.env.REACT_APP_DASHBOARD_URL}/integration/steps`}
                      target={"_blank"}
                      className="footer-text ff-book lg-px-5"
                    >
                      Complete Setup
                    </a>
                  ) : (
                    <div
                      className="footer-text ff-book lg-px-5 cursor-pointer footer-login-link"
                      onClick={() =>
                        (window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/signin`)
                      }
                    >
                      Login
                    </div>
                  )}
                  <img
                    className="footer-link-separator"
                    src={
                      require("../../assets/img/Priya_AI/footer-border.png")
                        .default
                    }
                  />
                  <Link
                    to="/privacypolicy"
                    target={"_blank"}
                    className="footer-text ff-book lg-px-5 footer-privacy-link"
                  >
                    Privacy Policy
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <div className="ff-book text-center footer-text pt-5 " id="footer-copyright">
            Copyright© 2024, Y CORP | All Rights Reserved.
          </div>
        </div>
      </div>
    </>
  );
}




