import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

const PricingStats = ({ modalToggle }) => {
  const [windowSize, setWindowSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setWindowSize(true);
      } else {
        setWindowSize(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <div className="text-center">
      <h1
        className="ff-inter-regular text-dark px-lg-5 mx-lg-5"
        style={{
          lineHeight: "40px",
          textAlign: windowSize ? "left" : "center",
          fontSize: windowSize ? "20px" : "36px",
        }}
      >
        Optimizing prices helps the average <br /> e-commerce store...
      </h1>
      <Row
        className="my-lg mx-0 text-dark flex-wrap"
        style={{
          gap: "2.5rem 0rem",
          // gap: "0",
          justifyContent: "center",
        }}
      >
        <Col style={{ maxWidth: "30%", minWidth: "190px" }}>
          <Row
            style={{
              alignItems: "center",
              justifyContent: "center",
              // width: 'max-content'
            }}
          >
            <span style={{ fontSize: "2em" }}>↑ </span>
            <h2
              className="ff-light"
              style={{ color: "black", fontSize: "4.5em", marginBottom: 0 }}
            >
              18%
            </h2>
          </Row>
          <div
            style={
              {
                // width: 'max-content'
              }
            }
            className="text-gray ff-inter-regular"
          >
            Increase in conversions
          </div>
        </Col>
        <Col style={{ maxWidth: "30%", minWidth: "190px" }}>
          <Row
            style={{
              alignItems: "center",
              justifyContent: "center",
              // width: 'max-content'
            }}
          >
            <span style={{ fontSize: "2em" }}>↑ </span>
            <h2
              className="ff-light"
              style={{ color: "black", fontSize: "4.5em", marginBottom: 0 }}
            >
              23%
            </h2>
          </Row>
          <div
            style={
              {
                // width: 'max-content'
              }
            }
            className="text-gray ff-inter-regular"
          >
            In total revenue
          </div>
        </Col>
        <Col style={{ maxWidth: "30%", minWidth: "190px" }}>
          <Row
            style={{
              alignItems: "center",
              justifyContent: "center",
              // width: 'max-content'
            }}
          >
            <span style={{ fontSize: "2em" }}>↑ </span>
            <h2
              className="ff-light"
              style={{ color: "black", fontSize: "4.5em", marginBottom: 0 }}
            >
              16%
            </h2>
          </Row>
          <div
            style={
              {
                // width: 'max-content'
              }
            }
            className="text-gray ff-inter-regular"
          >
            In average order value
          </div>
        </Col>
      </Row>
      <div className="gap-2 d-flex justify-content-center">
        <button
          onClick={modalToggle}
          className="ff-bold mt-3 w-full py-3"
          style={{
            color: "white",
            backgroundColor: "black",
            borderRadius: "100px",
            paddingLeft: 40,
            paddingRight: 40,
          }}
        >
          ANALYZE MY PRODUCTS
        </button>
      </div>
    </div>
  );
};
export default PricingStats;
