import { Row, Col } from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React, { useRef, useState } from "react";
import ShopifyLogo from "../../../assets/img/shopify.png";
import AvatarOne from "../../../assets/img/image1.png";
import AvatarTwo from "../../../assets/img/image2.png";
import AvatarThree from "../../../assets/img/image3.png";
import AvatarFour from "../../../assets/img/image4.png";
import AvatarFive from "../../../assets/img/image5.png";
import DashboardImage from "../../../assets/img/dashboard.png";
import DashboardImage2 from "../../../assets/img/dashboard2.png";
import Arrow from "../../../assets/img/arrow.png";
import "./banner.css";
import PrimaryButton from "components/ui/PrimaryButton";
import SecondaryButton from "components/ui/SecondaryButton";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import shopifyAutopilotVideo from "../../../assets/video/Shopify_Autopilot.mp4"

export default function Banner() {
  const [isMuted, setIsMuted] = useState(true); // sound is muted by default
  const videoRef = useRef(null);

  const toggleSound = () => {
    setIsMuted(!isMuted);
    if (videoRef.current) {
      videoRef.current.muted = !isMuted; // Toggle the mute state on video
    }
  };

  return (
    <>
      <div className="bg-banner">
        <div>
          <section className="py-0 nav-section">
            <IndexNavbar className="fixed-top" />
          </section>
          <section>
            <div className="banner-mobile pt-0">
              <Row className="d-flex justify-content-center text-center">
                <Col lg="8" className="d-flex flex-column align-items-center hero-first-col">
                  <div className="hero-first-section">
                    <h1 className="mb-1 gradient-text">MAXIMIZE YOUR REVENUE</h1>
                    <h3 className="mb-0 subtitle">
                      WITH AUTOPILOT PRICING™
                    </h3>
                    <p className="responsive-paragraph">
                      Use AI to see how well you&apos;re pricing your products, and how
                      your prices can be improved to maximize profit.
                    </p>
                    <div style={{ gap: '15px' }} className=" d-flex justify-content-md-center justify-content-lg-start hero-first-section-btn-div ">
                      <PrimaryButton id="desktop-hero-primary-btn" title='GET STARTED FOR FREE' />
                      <PrimaryButton id="mobile-hero-primary-btn" className="secondary-btn-div mobile-primary-ml" title='TRY IT FREE' />
                      <SecondaryButton title='BOOK A DEMO' className="mobile-secondary-ml" />
                    </div>
                  </div>

                  <Row className="justify-content-center align-items-center mt-5">
                    <div className="custom-container">
                      <div className="custom-content">
                        <img
                          src={ShopifyLogo}
                          alt="Shopify logo"
                          className="shopify-logo"
                        />
                        <div className="vertical-divider"></div>
                        <div className="custom-stars ">
                          <div className="stars">
                            {[...Array(5)].map((_, i) => (
                              <svg
                                key={i}
                                className="star-icon"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 15l-5.878 3.09L5.56 11.09.683 7.91l6.006-.526L10 2l3.311 5.384 6.006.526-4.878 3.18L15.878 18z" />
                              </svg>
                            ))}
                          </div>
                          <span className="text trusted-hero-text ">
                            Trusted by 300+ Shopify Stores
                          </span>
                        </div>
                      </div>

                      <div className="vertical-divider d-none sm-visible"></div>

                      <div className="sm-visible d-none custom-stars">
                        <div className="stars">
                          {[...Array(5)].map((_, i) => (
                            <svg
                              key={i}
                              className="star-icon"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 15l-5.878 3.09L5.56 11.09.683 7.91l6.006-.526L10 2l3.311 5.384 6.006.526-4.878 3.18L15.878 18z" />
                            </svg>
                          ))}
                        </div>
                        <span className="trusted-text">
                          Trusted by 300+ Shopify Stores
                        </span>
                      </div>

                      <div className="avatars ml-4">
                        <img
                          src={AvatarOne}
                          alt="Avatar 1"
                          className="avatar"
                        />
                        <img
                          src={AvatarTwo}
                          alt="Avatar 2"
                          className="avatar avatar-margin"
                        />
                        <img
                          src={AvatarThree}
                          alt="Avatar 3"
                          className="avatar avatar-margin"
                        />
                        <img
                          src={AvatarFour}
                          alt="Avatar 4"
                          className="avatar avatar-margin"
                        />
                        <img
                          src={AvatarFive}
                          alt="Avatar 5"
                          className="avatar avatar-margin"
                        />
                      </div>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </div>
      <div>
        <Row style={{ position: 'relative' }} className="shopify-video-div mt-4 justify-content-center px-5">
          <video
            ref={videoRef}
            autoPlay
            loop
            muted={isMuted}
            playsInline // Important for iOS to avoid full-screen
            // className="shopify-video"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              pointerEvents: "none", // Disable clicking on the video to prevent fullscreen
            }}
          >
            <source src={shopifyAutopilotVideo} type="video/mp4" />
          </video>
          <button
            onClick={toggleSound}
            className="video-volume-btn"
            style={{
              position: "absolute",
              bottom: "2%",
              background: "rgba(0, 0, 0, 0.5)",
              border: "none",
              color: "white",
              cursor: "pointer",
              padding: "10px",
              borderRadius: "50%",
            }}
          >
            {isMuted ? <FaVolumeMute className="video-volume-btn-icon" /> : <FaVolumeUp className="video-volume-btn-icon" />}
          </button>
          {/* <img
            src={DashboardImage}
            alt="Description of the image"
            className="custom-dashboard-image"
          /> */}
        </Row>
      </div>
      <Row className="mt-5">
        <div className="container">
          <div className="text-container">
            <p className="dynamic-text">
              ON AVERAGE, SWITCHING TO DYNAMIC PRICING HELPS ECOMMERCE STORES...
            </p>
          </div>

          <div className="stats-container flex">
            <div className="conversion-stat">
              <div className="conversion-value ff-light">
                <img src={Arrow} alt="Arrow" className="arrow-icon" />
                18%
              </div>
              <p className="conversion-description">Increase in conversions</p>
            </div>

            <div className="revenue-stat">
              <div className="revenue-value ff-light">
                <img src={Arrow} alt="Arrow" className="arrow-icon" />
                23%
              </div>
              <p className="revenue-description">In total revenue</p>
            </div>

            <div className="order-value-stat">
              <div className="order-value ff-light">
                <img src={Arrow} alt="Arrow" className="arrow-icon" />
                16%
              </div>
              <p className="order-description">In average order value</p>
            </div>
          </div>
        </div>
      </Row>

      <section>
        <div>
          <Row className="text-center">
            <Col>
              <div className="ai-analysis-container">
                <div className="ai-analysis-content">
                  <div className="ai-analysis-title">
                    <p className="title-text mb-0">
                      Get a Free AI Analysis of Your Product’s Prices
                    </p>
                  </div>
                  <div className="ai-analysis-description">
                    <p className="description-text">
                      See exactly how well your prices are doing vs the market,
                      and how you can optimize them for more revenue.
                    </p>
                  </div>
                </div>
                <PrimaryButton title='GET STARTED FOR FREE' />
              </div>
            </Col>
          </Row>
          <Row className="mt-5" id="heroBottomBanner" >
            <Col className="position-relative text-center">
              <img
                src={DashboardImage2}
                alt="Dashboard 2"
                className="img-fluid rounded BottomBanner custom-dashboard-image"
                style={{ borderRadius: "10px", }}
              />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
