import React from 'react'
import './PriceEfficiencySection.css'

import priceEfficiencyGraph from "../assets/img/Home/PriceEfficiencyGraph.svg"
import priceEfficiencyGraphMini from "../assets/img/Home/priceEfficiencyGraphMini.svg"

const PriceEfficiencySection = () => {
    return (
        <div className='PE-Main'>
            <div className='PE-S1'>
                <div>Stop leaving money on the table with old, static prices</div>
                <div>On average, merchants who use dynamic pricing with their products see up to 23% in additional revenue over time.</div>
                <div>
                    <div>
                        <div /><div>STATIC</div>
                    </div>
                    <div>
                        <div /><div>DYNAMIC</div>
                    </div>
                </div>
            </div>
            <div className='PE-S2'>
                <img src={priceEfficiencyGraph} alt="Price Efficiency Graph" className='priceEfficiencyGraph' />
                <img src={priceEfficiencyGraphMini} alt="Price Efficiency Graph" className='priceEfficiencyGraphMini' />
            </div>
        </div>
    )
}

export default PriceEfficiencySection
