import Footer from "components/Footer";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import WhiteBGNavbar from "components/Navbars/WhiteBGNavbar";
import TrustSection from "components/TrustSection";
import PricingStats from "./components/PricingStats";
import GuideSection from "components/GuideSection";
import AnalysisSection from "components/AnalysisSection";
import TestimonialSection from "components/TestimonialSection";
import { FaTimes } from "react-icons/fa";
import { Loader } from "react-feather";
import URLInput from "./components/urlInput";

const PricingAnalysis = () => {
  const [validate, setValidate] = useState(false);
  const [value, setValue] = useState("");
  const [domain, setDomain] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const { pathname } = useLocation();

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [windowSize, setWindowSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setWindowSize(true);
      } else {
        setWindowSize(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "white";
    document.body.style.overflowX = "hidden";
  }, []);

  const handleChange = (e) => {
    setDomain(e.target.value + ".myshopify.com");
    setValue(e.target.value);
    setValidate(false);
  };

  const IMAGES = [
    require("../../../assets/img/Priya_AI/amazon_transparent.png").default,
    require("../../../assets/img/Priya_AI/shopify_transparent.png").default,
    require("../../../assets/img/Priya_AI/walmart_transparent.png").default,
    require("../../../assets/img/Priya_AI/bailly_transparent.png").default,
    require("../../../assets/img/Priya_AI/ali_express_transparent.png").default,
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PRIYA | Pricing Analysis</title>
        <meta name="title" content="PRIYA | Pricing Analysis" />
        <meta
          name="description"
          content="The World's First Price Prediction Model. PPM is used by PRIYA to predict the next price with the highest likelihood of a conversion for any product or service."
        />
      </Helmet>
      <div
        className="pricinganalysis-section"
        style={{
          backgroundColor: "#f3f4f5",
        }}
      >
        <>
          <Modal
            isOpen={isModalOpen}
            toggle={toggle}
            backdrop={true}
            size="lg"
            className="modal-dialog-centered payment-popup shopify-popup"
          >
            <ModalHeader
              toggle={toggle}
              className="py-4"
              style={{ backgroundColor: "#f4f4f4" }}
            >
              <div className="text-center mt-3">
                <a href={`/`} target="_blank">
                  <img
                    src={
                      require("../../../assets/img/Priya_AI/logo-black.png")
                        .default
                    }
                    className="img-fluid ml-4 logo-shopify-mobile"
                    width={145}
                  />
                </a>
              </div>
              <div className="close-btn">
                <FaTimes size={15} className="cursor-pointer" />
              </div>
            </ModalHeader>
            <ModalBody
              className="p-2 p-sm-4"
              style={{ backgroundColor: "#f4f4f4" }}
            >
              <div className="position-rel">
                <div className={`${false ? "bg-hide" : ""}`}>
                  <Form className="my-4 text-center">
                    <div className="shopify-popup">
                      <FormGroup>
                        <h3 className="text-gray-shopify ff-inter-semi mb-0">
                          Enter Your Shopify Store Domain
                        </h3>
                        <p className="text-gray ff-inter-semi text-16 mb-3">
                          (Input the .myshopify.com one, not your public domain)
                        </p>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          <InputGroup>
                            <Input
                              type="text"
                              onChange={handleChange}
                              className="ff-inter-medium"
                            />
                            <InputGroupText className="ff-inter-medium">
                              .myshopify.com
                            </InputGroupText>
                          </InputGroup>
                          {/* {(validate) && <>
                                                <p className='text-red text-14 ff-inter-regular'>{"HI"}</p>
                                            </>} */}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="mt-4">
                      <button
                        className={`text-16 btn-reconnect green-button ff-inter-semi py-2`}
                        type="button"
                        onClick={() => {
                          if (value === "") {
                            // setValidate(true)
                            // setMessage('Please enter your shop URL')
                          } else {
                            // onClickVerify(domain, setLoading, toggle)
                          }
                        }}
                      >
                        Continue
                      </button>
                    </div>
                    <p className="text-gray ff-inter-regular text-center my-3">
                      By continuing, you agree to our{" "}
                      <a
                        href={`/privacypolicy`}
                        target={"_blank"}
                        className="text-blue"
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </Form>
                </div>
                {false && (
                  <div className="position-loader">
                    <Loader />
                  </div>
                )}
              </div>
            </ModalBody>
          </Modal>
        </>
        <div
          className="bg-light-gray"
          style={{
            maxWidth: "1650px",
            margin: "0 auto",
          }}
        >
          <div style={{ position: "absolute", top: "-5rem", left: "0" }}>
            <img
              src={
                require("../../../assets/img/Priya_AI/grid-white.png").default
              }
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
          <section className="py-0 nav-section">
            <WhiteBGNavbar className="fixed-top" />
          </section>
          <section
            className="mb-0 my-sm-5"
            style={{
              paddingTop: 0,
              paddingBottom: "50px",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: "98px",
                right: "0",
                display: windowSize ? "none" : "block",
              }}
            >
              <img
                src={
                  require("../../../assets/img/Priya_AI/grid-white.png").default
                }
                style={{ transform: "rotate(360deg)" }}
              />
            </div>
            <Row className="align-items-center">
              <Col xs="12" md="6" className="order-2 order-md-1">
                <div>
                  <h2
                    className="text-uppercase font-banner-research mb-0 ff-inter-semi text-dark mr-sm-4 mt-2"
                    style={{ fontSize: "50px" }}
                  >
                    Get a Free Pricing Analysis of your products
                  </h2>
                  <p
                    className="ff-inter-light text-gray font-style mt-4 mb-5"
                    style={{
                      fontSize: "25px",
                      width: windowSize ? "100%" : "70%",
                    }}
                  >
                    Use AI to check how optimal your current prices are, or if
                    your prices need to be adjusted to make the most profit from
                    every sale.
                  </p>
                </div>
              </Col>
              <Col xs="12" md="6" className="order-3 order-md-2 mb-4 mb-md-0">
                <img
                  className="banner-img"
                  style={{
                    width: "100%",
                    maxWidth: "600px",
                    margin: "0 auto",
                    display: windowSize ? "none" : "block",
                  }}
                  src={
                    require("../../../assets/img/Priya_AI/banner img.png")
                      .default
                  }
                />
              </Col>
              <Col xs="12" className="order-4">
                <div
                  className="d-flex flex-wrap"
                  style={{
                    gap: "1rem",
                    marginBottom: "3rem",
                    marginTop: "-3rem",
                  }}
                >
                  <URLInput />
                  <div
                    className="d-flex justify-content-center"
                    style={{ maxWidth: "200px", flexGrow: 1 }}
                  >
                    <button
                      className="ff-bold w-full py-3"
                      style={{
                        color: "white",
                        backgroundColor: "black",
                        borderRadius: "100px",
                        paddingLeft: 50,
                        paddingRight: 50,
                        width: "100%",
                      }}
                      onClick={() => {
                        // setIsModalOpen(true);
                        // Redirect to Dashboard.
                      }}
                    >
                      GET STARTED
                    </button>
                  </div>
                </div>
                <div>
                  <TrustSection />
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-around mt-lg-xl mt-5">
              {IMAGES.map((src, idx) => (
                <img
                  key={idx}
                  src={src}
                  style={{ width: windowSize ? "70%" : "auto" }}
                />
              ))}
            </Row>
          </section>

          {/* <section
            className="mb-0 my-sm-5"
            style={{ paddingTop: 0, paddingBottom: "50px", maxWidth: '1650px', margin: '0 auto' }}
          >
            <Row className="align-items-center" style={{ paddingTop: "4rem" }}>
              <Col xs="12" md="6" className="order-2 order-md-1">
                <div>
                  <h2
                    className="text-uppercase font-banner-research mb-0 ff-light text-dark mr-sm-4 mt-2"
                    style={{ fontSize: "50px" }}
                  >
                    Get a Free Pricing Analysis of your products
                  </h2>
                  <p
                    className="text-gray font-style ff-light mt-4 mb-5"
                    style={{ fontSize: "25px", maxWidth: "70%" }}
                  >
                    Use AI to check how optimal your current prices are, or if
                    your prices need to be adjusted to make the most profit from
                    every sales.
                  </p>
                </div>
              </Col>
              <Col xs="12" md="6" className="order-3 order-md-2 mb-4 mb-md-0">
                <img
                  style={{
                    width: "100%",
                    maxWidth: "600px",
                    margin: "0 auto",
                    display: "block",
                  }}
                  src={
                    require("../../../assets/img/Priya_AI/banner img.png")
                      .default
                  }
                />
              </Col>
              <Col xs="12" className="order-4">
                <div
                  className="d-flex flex-wrap"
                  style={{ gap: "1rem", marginBottom: "3rem" }}
                >
                  <div
                    className="d-flex"
                    style={{
                      backgroundColor: "white",
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: "30px",
                      padding: "1rem",
                      paddingLeft: "1em",
                      paddingRight: "1em",
                      gap: "1em",
                      maxWidth: "430px",
                      flexGrow: 1,
                    }}
                  >
                    <input
                      placeholder="Enter your Shopify store URL"
                      type="text"
                      style={{ border: "none", outline: "none", flexGrow: 1 }}
                    />
                    <p className="text-gray">|</p>
                    <p className="text-gray">.myshopify.com</p>
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    style={{ maxWidth: "200px", flexGrow: 1 }}
                  >
                    <button
                      className="ff-bold w-full py-3"
                      style={{
                        color: "white",
                        backgroundColor: "black",
                        borderRadius: "100px",
                        paddingLeft: 50,
                        paddingRight: 50,
                        width: "100%",
                      }}
                      onClick={() => {setIsModalOpen(true)}}
                    >
                      GET STARTED
                    </button>
                  </div>
                </div>
                <TrustSection />
              </Col>
            </Row>
            <Row className="d-flex justify-content-around mt-5">
              {IMAGES.map((src, idx) => (
                <img
                  key={idx}
                  src={src}
                />
              ))}
            </Row>
          </section> */}
        </div>
        <div className="bg-white">
          <section style={{ paddingTop: "90px", paddingBottom: "90px" }}>
            <PricingStats modalToggle={toggle} />
          </section>
        </div>
        <div className="bg-light-gray">
          <section>
            <GuideSection modalToggle={toggle} />
          </section>
        </div>
        <div className="bg-white">
          <div style={{ maxWidth: "1650px", margin: "0 auto" }}>
            <section style={{ paddingRight: 0 }}>
              <AnalysisSection modalToggle={toggle} />
            </section>
          </div>
        </div>
        <div className="bg-light-gray">
          <section>
            <TestimonialSection />
          </section>
        </div>
        <div className="bg-white">
          <section className="pb-4">
            <h1
              className="ff-inter-regular text-dark px-lg-5 mx-lg-5"
              style={{
                lineHeight: "40px",
                fontSize: 36,
                textAlign: windowSize ? "left" : "center",
              }}
            >
              Learn how PRIYA optimizes prices for
              <br />
              merchants and small businesses.
            </h1>
            <div className="mt-5 mb-sm-5 text-center">
              <div
                class="video-container"
                style={{
                  position: "relative",
                  width:
                    "clamp(350px, 70%, 1000px)" /* Minimum 300px, prefers 50% of the viewport width, maximum 800px */,
                  height:
                    "clamp(200px, 28.13vw, 450px)" /* Minimum 169px, prefers 28.13% of the viewport height, maximum 450px */,
                  margin: "0 auto",
                  paddingBottom: "calc(min(70%, 100%) * 9 / 16)",
                  // height: 0,
                  overflow: "hidden",
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    border: 0,
                  }}
                  src="https://www.youtube.com/embed/lEqHLVTf4p4?si=5s2_4LpYXHYu8g6k"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
              {/* <iframe style={{width: '75%', minHeight: "200px"}} src="https://www.youtube.com/embed/lEqHLVTf4p4?si=5s2_4LpYXHYu8g6k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
            </div>
            <Container>
              <div className="gap-2 d-flex justify-content-center mb-xl mt-5 mt-md-0">
                <button
                  onClick={toggle}
                  className="ff-bold mt-3 w-full py-3"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    borderRadius: "100px",
                    paddingLeft: 40,
                    paddingRight: 40,
                  }}
                >
                  OPTIMIZE MY PRICES
                </button>
              </div>
            </Container>
          </section>
        </div>
        <Footer text={"Get started for free now."} />
      </div>
    </>
  );
};

export default PricingAnalysis;
