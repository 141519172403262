import React from 'react'
import './ui.css'

const SecondaryButton = ({ backgroundColor, color, title, borderColor, borderWidth, className }) => {
    return (
        <div className={`secondary-btn-div ${className}`} style={{ backgroundColor: backgroundColor ? backgroundColor : 'transparent', color: color ? color : 'white', borderColor: borderColor ? borderColor : '#999999', borderWidth: borderWidth ? borderWidth : '2px' }}>
            <a target='_blank' href="https://calendly.com/priyaai/30min">
                {title}
            </a>
        </div>
    )
}
export default SecondaryButton
