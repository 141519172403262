import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
// Custom
import Index from "views/priya_ai/Home";
import { AnchorScroll } from "helper";
import Pricing from "views/priya_ai/Pricing";
import Research from "views/priya_ai/Research";
import About from "views/priya_ai/About";
import { Privacy } from "views/priya_ai/Privacy";
import { Terms } from "views/priya_ai/Terms";
import AIPricingTool from "views/priya_ai/Research/ArticlePage";
import ArticleTwo from "views/priya_ai/Research/Article2Page";
import PPM from "views/priya_ai/PPM-1";
import ArticleThree from "views/priya_ai/Research/Article3Page";
import ArticleFour from "views/priya_ai/Research/Article4Page";
import PricingAnalysis from "views/priya_ai/PricingAnalysis";

export default function Routes() {
  React.useEffect(() => {
    AOS.init();
    AnchorScroll();
  });

  window.onbeforeunload = function () {
    alert("You work will be lost");
  };

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={(props) => <Index {...props} />} />
          <Route
            exact
            path="/pricing"
            render={(props) => <Pricing {...props} />}
          />
          <Route
            exact
            path="/research"
            render={(props) => <Research {...props} />}
          />
          <Route
            exact
            path="/research/what-is-an-ai-pricing-tool"
            render={(props) => <AIPricingTool {...props} />}
          />
          <Route
            exact
            path="/research/11-best-Shopify-plugins-for-your-online-store"
            render={(props) => <ArticleTwo {...props} />}
          />
          <Route
            exact
            path="/research/what-separates-PRIYA-from-other-AI-pricing-tools"
            render={(props) => <ArticleThree {...props} />}
          />
          <Route
            exact
            path="/research/why-dynamic-pricing-is-better"
            render={(props) => <ArticleFour {...props} />}
          />
          <Route exact path="/about" render={(props) => <About {...props} />} />
          <Route
            exact
            path="/privacypolicy"
            render={(props) => <Privacy {...props} />}
          />
          <Route
            exact
            path="/termsofuse"
            render={(props) => <Terms {...props} />}
          />
          <Route exact path="/ppm-1" render={(props) => <PPM {...props} />} />
          <Route exact path="/pricinganalysis" render={(props) => <PricingAnalysis {...props} />} />
        </Switch>
      </BrowserRouter>
    </>
  );
}
