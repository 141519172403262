import React, { useEffect, useState } from "react";

const URLInput = () => {
  const [windowSize, setWindowSize] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setWindowSize(true);
      } else {
        setWindowSize(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  return (
    <div
      className="d-flex align-items-center"
      style={{
        width: windowSize ? "350px" : "550px",
        // width: "clamp(250px, 50vw, 550px)",
        position: "relative",
      }}
    >
      <input
        placeholder="Enter Your Shopify Store URL"
        style={{
          backgroundColor: "white",
          borderRadius: "30px 0 0 30px",
          padding: "1rem",
          paddingLeft: windowSize ? ".8rem" : "1.8em",
          paddingRight: windowSize ? ".5rem" : "1.7em",
          border: "none",
          outline: "none",
          minWidth: windowSize ? "auto" : "280px",
          width: "65%",
          position: "relative",
        }}
        type="text"
      />
      <div
        style={{
          padding: "1rem",
          backgroundColor: "white",
          borderRadius: "0 30px 30px 0",
          border: "1px solid transparent",
          borderLeftColor: "#7C7C7C",
          color: "#7C7C7C",
          width: "35%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        .myshopify.com
      </div>
    </div>
  );
};

export default URLInput;
